import React from 'react';


export interface TextFieldProps {
    label: string;
    name: string;
    type?: string;
    value?: string;
    isInEditMode?: boolean;
    isSubmitted?: boolean;
    errorText?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextField = ({
                              label,
                              name = label.toLowerCase(),
                              type = "text,",
                              value,
                              isInEditMode = true,
                              isSubmitted = false,
                              errorText,
                              onChange,
                              ...props
                          }: TextFieldProps) => {

    const inputClassBorderColor = errorText?.length && errorText?.length > 0 ? " border-red-600 " :
        isSubmitted ? " border-green-600 " : " border-gray-300 "
    const inputClassBorder =
        "focus:border-b-2 appearance-none border-b "
    const inputClassNameString = "bg-transparent block py-2.5 px-0 w-full text-sm text-gray-900 " +
        " border-0 appearance-none focus:outline-none focus:ring-0 peer " +
        (isInEditMode ? inputClassBorder : "border-transparent ") + inputClassBorderColor
    const labelClassNameString = "absolute text-sm text-gray-500 " +
        "duration-300 transform -translate-y-6 top-3 -z-10 origin-[0] peer-focus:left-0 " +
        "peer-focus:text-summer-green-600 peer-focus: " +
        "peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 "
    // "peer-focus:-translate-y-6"

    const voidChange = () => {
    }
    // "peer-focus:scale-75 peer-focus:-translate-y-6"

    return (


        <div className="relative z-0 mb-6 w-full group">
            <label htmlFor={name} className={labelClassNameString}>
                {label}</label>
            <input name={name}
                   type={type}
                   className={inputClassNameString}
                   placeholder=""
                   value={value}
                   onChange={isInEditMode ? onChange : voidChange}/>
            <p className={"text-red-600 text-sm mt-2"}>{errorText}</p>
        </div>

    );
}
