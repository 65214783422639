import React, {FC} from "react"
import {graphql, PageProps} from "gatsby";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image"

import {Layout} from "../components/Layout";
import {OrderLine} from "../components/OrderLine";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import slides from "../data/slides";
import photos from "../data/photos";


const ShopPage = ({data}: PageProps<Queries.ShopItemsQuery>) => {
    const [index, setIndex] = React.useState(-1);

// @ts-ignore
    const openLB = (e) => {
        const indx: number = +e.target?.id || 0
        setIndex(indx)
        console.log(e)
    }


    return (
        <Layout restrictedWidth={false}
                pageTitle={"De MTB specialist regio Deventer!✓ | Steven's bike service"}
                metaDescription={"Jouw mountainbike voor onderhoud of reparatie? Neem dan nu contact op met Steven's bike service! Dé MTB specialist van regio Deventer!✓"}>

            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={photos}
            />
            <div className={"fixed top-20 z-30 bg-white w-full mx-auto h-[25vh] shadow-lg"}>
                <div className={"max-w-6xl mx-auto"}>
                    <h1
                        className={"lg:leading-relaxed md:order-1 text-black text-3xl md:text-5xl lg:text-6xl order-4 md:order-4 mb-10"}>
                        Stevensbikeservice <span className={"font-bold"}>kleding</span>
                    </h1>
                    <p className={"leading-relaxed md:leading-relaxed lg:leading-relaxed my-5 px-2 col-span-2 text:xl md:text-2xl lg:text-2xl pt-5"}>
                        hier komt een formulier om je gegevens in te vullen en een overzicht van de bestelling tot nu
                        toe
                    </p>
                </div>
            </div>

            <div className={"h-[40vh]"}></div>

            <div className={"bg-white z-0 w-full pb-10"}>
                <div className=" max-w-6xl mx-auto md:grid md:grid-cols-4 lg:content-between">

                    <div className={"col-span-4 md:grid md:grid-cols-4 gap-16"}>
                        {data.gallery.nodes.map((node, indx) => (
                            node?.childImageSharp?.gatsbyImageData && (
                                <span key={node.id} id={indx.toString()} className="py-3" onClick={(e) => openLB(e)}>

                                    <GatsbyImage
                                        image={node?.childImageSharp?.gatsbyImageData}
                                        alt={node.base.split('-').join(' ').split('.')[0]}
                                        id={indx.toString()}/>
                                </span>)
                        ))}


                        <div className={"order-1 md:order-1 ml-auto"}>
                            <div className={"ml-auto"}>
                                <a href={"/experiment?blaat"}>

                                    <StaticImage src={"../images/shop/bib-short.png"} alt={"Bibshort"}
                                                 className={"shadow-lg bg-white shadow-gray-400"}/>
                                </a>
                            </div>
                            <div className={"h-full"}>
                                <OrderLine/>
                            </div>
                        </div>
                        <div className={"order-2 md:order-2 h-full leading-loose"}>
                            <div className={"ml-auto"}>
                                <StaticImage src={"../images/shop/maillot-elite-homme.png"} alt={"maillot-elite-homme"}
                                             className={"shadow-lg bg-white shadow-gray-400"}/>
                            </div>
                            <div className={"h-full"}>
                                <OrderLine/>
                            </div>
                        </div>
                        <div className={"order-3 md:order-3 ml-auto"}>
                            <div className={"ml-auto"}>
                                <StaticImage src={"../images/shop/bib-short.png"} alt={"Bibshort"}
                                             className={"shadow-lg bg-white shadow-gray-400"}/>
                            </div>
                            <div className={"h-full"}>
                                <OrderLine/>
                            </div>
                        </div>
                        <div className={"order-4 md:order-4 h-full leading-loose"}>
                            <div className={"ml-auto"}>
                                <StaticImage src={"../images/shop/maillot-elite-homme.png"} alt={"maillot-elite-homme"}
                                             className={"shadow-lg bg-white shadow-gray-400"}/>
                            </div>
                            <div className={"h-full"}>
                                <OrderLine/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ShopPage

export const pageQuery = graphql`
    query ShopItems {
      gallery: allFile {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              height: 900
              width: 600
              webpOptions: {quality: 100}
            )
          }
          publicURL
          id
          base
        }
      }
    }
 `
